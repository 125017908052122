import React from "react";
import Sahlender from "../images/Sahlender.jpg";
import "../Styles/Story.css";

function Story() {
  return (
    <div className="Story-Container" id="Story">
      <div className="Story-Text-Container">
        <h1>Meine Geschichte</h1>
        <p>
          Bereits seit dem Jahr 1987 betreibe ich eine kleine Bioland®-Bäckerei
          in Thannhausen im südlichen Schwaben zwischen Augsburg und Ulm. Schon
          während meiner Ausbildung zum Bäckermeister im Jahre 1982 hat mich die
          Backkunst und das traditionelle Bäckerhandwerk auf eine Art und Weise
          fasziniert, dass ich immer mehr versucht habe Backwaren so
          naturbelassen wie nur möglich herzustellen.
        </p>
      </div>
      <h1 className="Story-Title-Mobile">Meine Geschichte</h1>
      <div
        className="Story-Image"
        style={{ backgroundImage: `url(${Sahlender})` }}
      ></div>
      <p className="Story-Text-Mobile">
        Bereits seit dem Jahr 1987 betreibe ich eine kleine Bioland®-Bäckerei in
        Thannhausen im südlichen Schwaben zwischen Augsburg und Ulm. Schon
        während meiner Ausbildung zum Bäckermeister im Jahre 1982 hat mich die
        Backkunst und das traditionelle Bäckerhandwerk auf eine Art und Weise
        fasziniert, dass ich immer mehr versucht habe Backwaren so naturbelassen
        wie nur möglich herzustellen.
      </p>
    </div>
  );
}

export default Story;
