import React from "react";
import "../Styles/Baking.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Keyboard } from "swiper";

function Baking() {
  const slides = [
    {
      url:
        "https://images.unsplash.com/photo-1567042661848-7161ce446f85?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80",
      text:
        "Alle Brotsorten werden mit einem eigens hergestellten mild-säuerlichem Natursauerteig gebacken.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1549931319-a545dcf3bc73?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      text:
        "Der Sauerteig wird mit Vollkornschrot bei der Herstellung angereichert. Fehlt dieses Vollkornschrot kann der Körper nicht alle im Getreide vorhandenen Spurenelemente vollständig verwerten.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1555507036-ab1f4038808a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1026&q=80",
      text:
        "Alle Weizenprodukte werden mit einem enzymatisch aktiven Malzmehl hergestellt. Gemahlen wird dieses Bioland-Weizen Malz mit einer eigenen Mühle, schonend unter 42°C, mit Steinmahlwerk.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1559811814-e2c57b5e69df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      text:
        "Um meinen Kunden keinen Schaden zuzufügen verwenden wir statt Kristall oder Rohrohrzucker nur „Rapadura®“. Er enthält außer der Sacharose 6% Schalenbestandteile. Dieses ist fast einzigartig unter allen Süßungsmitteln. Und macht ihn deshalb so gut verwertbar im Körper. Als Salz setzen wir nur zertifiziertes Steinsalz ein, denn es enthält kein Mikroplastik.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1613396874083-2d5fbe59ae79?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=719&q=80",
      text:
        "Wir verwenden ausschließlich natürlich gepresste und unbehandelte Öle.",
    },
  ];

  return (
    <div className="Baking-Container" id="Baking">
      <h1 id="Baking">
        Unser täglicher Backbetrieb ist durch folgende Aspekte geprägt
      </h1>
      <Swiper
        modules={[Pagination, Keyboard]}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        keyboard
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide>
              <div className="Baking-Slide-Container">
                <div
                  className="Baking-Slide-Image"
                  style={{ backgroundImage: `url(${slide.url})` }}
                ></div>
                <p>{slide.text}</p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default Baking;
