import React, { useState } from "react";
import Signature from "../../src/images/Unterschrift Dick.svg";
import { NavHashLink as Link } from "react-router-hash-link";
import "../Styles/Nav.css";

function Nav() {
  const [drawer, setDrawer] = useState(false);

  const showDrawer = () => {
    setDrawer(!drawer);
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;

    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav>
      <Link to="/#Home" smooth>
        <img
          className="Nav-Image"
          src={Signature}
          alt="M. Sahlender Unterschrift"
        ></img>
      </Link>
      <div className="Nav-Text">
        <Link to="/#Story" scroll={(el) => scrollWithOffset(el, 170)}>
          Geschichte
        </Link>
        <Link to="/#Baking" scroll={(el) => scrollWithOffset(el, 100)}>
          Backbetrieb
        </Link>
        <Link to="/#Ebay" scroll={(el) => scrollWithOffset(el, 170)}>
          Shop
        </Link>
        <Link to="/#Contact" scroll={(el) => scrollWithOffset(el, 170)}>
          Kontakt
        </Link>
      </div>
      {/* ,mobile */}
      <div className="Mobile-Drawer">
        <div onClick={showDrawer} className="Menu">
          <span className="Hamburger-Menu" id="Hamburger1" />
          <span className="Hamburger-Menu" />
          <span className="Hamburger-Menu" />
        </div>
        <div
          className={drawer ? "Drawer active" : "Drawer"}
          onClick={showDrawer}
        >
          <div className="Drawer-Navigation">
            <li onClick={showDrawer}>
              <Link to="/#Story" scroll={(el) => scrollWithOffset(el, 100)}>
                Geschichte
              </Link>
            </li>
            <li onClick={showDrawer}>
              <Link to="/#Baking" scroll={(el) => scrollWithOffset(el, 100)}>
                Backbetrieb
              </Link>
            </li>
            <li onClick={showDrawer}>
              <Link to="/#Ebay" scroll={(el) => scrollWithOffset(el, 200)}>
                Shop
              </Link>
            </li>
            <li onClick={showDrawer}>
              <Link to="/#Contact" scroll={(el) => scrollWithOffset(el, 0)}>
                Kontakt
              </Link>
            </li>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
