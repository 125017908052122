import React from "react";
import Wheat from "../images/Wheatfield.png";
import "../Styles/Home.css";

function Home() {
  return (
    <div
      className="Home-Container"
      style={{ backgroundImage: `url(${Wheat})` }}
      id="Home"
    >
      <div className="Home-Text-Container">
        <h1>Liebe Besucher,</h1>
        <p>
          Mit dem Ziel reine Naturprodukte herzustellen, produzieren wir täglich
          frische Backwaren auch heute noch gänzlich ohne Konservierungsstoffe
          oder weitere Zutaten, die in der klassischen konventionellen
          Backindustrie verwendet werden.
        </p>
      </div>
    </div>
  );
}

export default Home;
