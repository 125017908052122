import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import "../Styles/Imprint.css";

function Imprint() {
  return (
    <>
      <Nav />
      <div className="Imprint-Container" id="Imprint">
        <h1 style={{ marginTop: "0px" }}>Impressum</h1>
        <p>
          <span>
            <b>Bioland Bäckerei Konditorei M. Sahlender</b>
          </span>
          <span>
            <b>
              Frühmeßstraße 21
              <br /> 86470 Thannhausen
            </b>
          </span>
          <span>
            <b>Telefon:</b> <a href="tel:08281 3411">08281 3411</a>
          </span>
          <span>
            <b> E-Mail:</b>{" "}
            <a href="mailto:M.Sahlender@t-online.de">m.sahlender@t-online.de</a>
          </span>
          <span>
            <b>Inhaber: </b>Michael Sahlender
          </span>
          <span>
            <b>Zuständige Kammer:</b> Handwerkskammer Augsburg Siebentischstraße
            52-58, 86161 Augsburg
            <br />
            <b>Registrierungsnummer:</b> 1475119
            <br />
            <b>Berufsbezeichnung:</b> Bäcker (verliehen in der Bundesrepublik
            Deutschland)
            <br />
            <b>Berufsrechtliche Regelungen:</b> Handwerksordnung in der Fassung
            der Bekanntmachung vom 24. September 1998 (BGBl. I S. 3074; 2006 I
            S. 2095), die zuletzt durch Artikel 283 der Verordnung vom 31.
            August 2015 (BGBl. I S. 1474) geändert worden ist.
          </span>
          <span>
            <b>
              Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz
              (UStG):
            </b>{" "}
            DE 1284422223
          </span>
          <span>
            <b>Bio-Kontrollstelle:</b> ÖKO-DE-006
          </span>
          <span>
            <b>
              Inhaltlich Verantwortlicher gemäß § 55 Rundfunkstaatsvertrag
              (RStV):
            </b>{" "}
            Michael Sahlender
          </span>
          <span>
            <b>Haftungsausschluss:</b>
            <br />
            Als Betreiber der Seiten sind wir gemäß § 6 Abs.1 MDStV und § 8
            Abs.1 TDG für eigene Inhalte nach den allgemeinen Gesetzen
            verantwortlich. Die Inhalte der Seiten wurden mit größter Sorgfalt
            erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
            Inhalte können wir jedoch keine Haftung übernehmen.
          </span>
          <span>
            <b>Haftung für Links:</b>
            <br />
            Unser Angebot enthält Links zu externen Webseiten, auf deren Inhalte
            wir keinen Einfluss haben. Deshalb können wir für diese fremden
            Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist der jeweilige Anbieter verantwortlich. Die Seiten wurden
            zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Eine permanente Kontrolle der verlinkten Seiten ist jedoch ohne
            konkrete Anhaltspunkte nicht zumutbar. Bei bekannt werden von
            Rechtsverletzungen werden wir diese Links umgehend entfernen.
          </span>
          <span>
            <b>Urheberrecht:</b>
            <br />
            Die Veröffentlichungen auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
            jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
            bedürfen der schriftlichen Zustimmung des jeweiligen Verfassers.
            Beiträge Dritter sind als solche gekennzeichnet. Downloads und
            Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
            Gebrauch gestattet.
          </span>
          <span>
            <b>Online-Beilegung von Streitigkeiten:</b>
            <br /> Die EU-Kommission hat eine Internetplattform zur
            Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient
            als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten
            betreffend vertragliche Verpflichtungen, die aus
            Online-Kaufverträgen erwachsen. Nähere Informationen sind unter dem
            folgenden Link verfügbar:{" "}
            <a href="http://ec.europa.eu/consumers/odr">
              http://ec.europa.eu/consumers/odr
            </a>
            . Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle sind wir weder bereit noch
            verpflichtet.
          </span>
        </p>
        <h1>Datenschutzerklärung nach DSGVO </h1>
        <p>
          <span>
            Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Deshalb
            halten wir uns an das Gebot der "Datensparsamkeit". Es werden weder
            "Cookies" noch sonstige Dienste oder Komponenten Dritter (z.B.
            Google, Facebook, JavaScripts, Schriften, etc.) in dieser Website
            verwendet. Jedoch informieren wir Sie nachfolgend gemäß den
            gesetzlichen Vorgaben des Datenschutzrechts über die Art, den Umfang
            und Zweck der Verarbeitung personenbezogener Daten durch unser
            Unternehmen. Bezüglich der Definition von Begriffen wie etwa
            „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art.
            4 DSGVO.
          </span>
          <span>
            <b>1. Name und Anschrift des Verantwortlichen</b>
            <br />
            Der Verantwortliche der Datenschutz-Grundverordnung und anderer
            nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
            datenschutzrechtlicher Bestimmungen ist:
            <br />
            <br />
            Bioland Bäckerei Konditorei M. Sahlender
            <br /> Frühmeßstraße 21
            <br /> 86470 Thannhausen, Deutschland <br /> E-Mail:{" "}
            <a href="mailto:M.Sahlender@t-online.de">
              m.sahlender@t-online.de
            </a>{" "}
            <br />
            Website: <a href="/">www.bioland-baeckerei.com</a>
          </span>

          <span>
            <b>2. Name und Anschrift des Datenschutzbeauftragten </b>
            <br />
            Der Datenschutzbeauftragte des Verantwortlichen (siehe oben) ist:
            <br />
            <br />
            Michael Sahlender
            <br /> Frühmeßstraße 21
            <br /> 86470 Thannhausen, Deutschland
            <br />
            E-Mail:{" "}
            <a href="mailto:M.Sahlender@t-online.de">m.sahlender@t-online.de</a>
            <br /> Website: <a href="/">www.bioland-baeckerei.com</a>
          </span>

          <span>
            <b>3. Allgemeines zur Datenverarbeitung </b>
          </span>
          <span>
            <b>3.1. Umfang der Verarbeitung personenbezogener Daten </b>
            <br />
            Ihre Privatsphäre ist uns wichtig. Daher verarbeiten wir
            personenbezogene Daten unserer Nutzer nur, soweit dies zur
            Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte
            und Leistungen erforderlich ist. Die Verarbeitung personenbezogener
            Daten unserer Nutzer erfolgt nur nach Einwilligung des Nutzers. Eine
            Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung
            einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
            die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet
            ist.
          </span>
          <span>
            <b>
              3.2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten{" "}
            </b>
            <br />
            Die Verarbeitung ist rechtmäßig, wenn mindestens eine der
            nachstehenden Bedingungen erfüllt ist: Soweit wir für
            Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
            betroffenen Person einholen, dient Art. 6 Abs. 1 lit. (lat. littera
            = Buchstabe) a EU-Datenschutzgrundverordnung (DSGVO) als
            Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten,
            die zur Erfüllung eines Vertrages, dessen Vertragspartei die
            betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b
            DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge,
            die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
            rechtlichen Verpflichtung erforderlich ist, gegenüber den wir als
            Verantwortliche unterliegen, dient Art. 6 Abs. 1 lit. c DSGVO als
            Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person eine
            Verarbeitung personenbezogener Daten erforderlich machen, um diese
            zu schützen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
            Falls die Verarbeitung für die Wahrnehmung unserer Aufgabe, die im
            öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
            erfolgt, erforderlich ist, dient Art. 6 Abs. 1 lit. e DSGVO als
            Rechtsgrundlage. Falls die Verarbeitung zur Wahrung der berechtigten
            Interessen unseres Unternehmens oder eines Dritten erforderlich ist,
            sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
            betroffenen Person, die den Schutz personenbezogener Daten
            erfordern, überwiegen, dient Art. 6 Abs. 1 lit. f DSGVO als
            Rechtsgrundlage.
          </span>
          <span>
            <b>3.3. Datenlöschung und Speicherdauer </b>
            <br />
            Die personenbezogenen Daten der betroffenen Person werden gelöscht
            oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
            Speicherung kann darüber hinaus erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
            Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
            Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
            Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
            Erforderlichkeit zur weiteren Speicherung der Daten für einen
            Vertragsabschluss oder eine Vertragserfüllung besteht.
          </span>
          <span>
            <b>4. Server-Log Dateien</b>
          </span>
          <span>
            <b>4.1. Beschreibung und Umfang der Datenverarbeitung</b>
            <br />
            Aus technischen Gründen werden von dem Server, der diese Seite
            bereitstellt, automatisch Informationen über den Zugriff in
            sogenannten "Server-Logfiles" (Zugriffsprotokoll) gespeichert. Diese
            Information werden automatisch von Ihrem Browser übermittelt und
            enthalten folgende Daten:
            <br />
            <br />
            •Browsertyp und Browserversion
            <br /> •verwendetes Betriebssystem
            <br /> •Referrer URL
            <br /> •Hostname des zugreifenden Rechners
            <br /> •Uhrzeit der Serveranfrage
            <br />
            •IP-Adresse (anonymisiert)
            <br />
            <br />
            Diese Informationen sind anonymisiert, keiner konkreten Person
            zuordenbar und werden nicht mit Daten aus anderen Quellen
            zusammengeführt oder an Dritte weitergegeben.
          </span>
          <span>
            <b>4.2. Rechtsgrundlage für die Datenverarbeitung</b>
            <br />
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO,
            der die Verarbeitung von Daten zur Erfüllung eines Vertrags
            (Anzeigen dieser Website) oder vorvertraglicher Maßnahmen gestattet.
          </span>
          <span>
            <b>4.3. Zweck der Datenverarbeitung</b>
            <br />
            Die Protokolldaten dienen ausschließlich dem technischen Betrieb
            (z.B. der Protokollierung von Fehlfunktionen) sowie der
            Dokumentation von rechtswidrigen Zugriffen oder Nutzung der Website.
          </span>
          <span>
            <b>4.4. Dauer der Speicherung</b>
            <br />
            Die Server-Logfiles werden in aller Regel sechs Monate gespeichert.
            Bei Unregelmäßigkeiten oder Verdacht auf rechtswidrige Nutzung
            können diese zur Auswertung auch länger gespeichert werden.
          </span>
          <span>
            <b>4.5. Widerspruchs- und Beseitigungsmöglichkeit</b>
            <br />
            In diesem Fall gibt es keine Widerspruchs- und
            Beseitigungsmöglichkeit, da die Server-Log-Dateien essenziell sind,
            sodass die Webseite technisch funktionsfähig ist.
          </span>
          <span>
            <b>5. E-Mail Kontakt </b>
          </span>
          <span>
            <b>5.1. Beschreibung und Umfang der Datenverarbeitung</b>
            <br />
            Eine Kontaktaufnahme ist über die bereitgestellte E-Mail-Adresse
            möglich. In diesem Fall werden die mit der E-Mail übermittelten
            personenbezogenen Daten des Nutzers gespeichert. Es erfolgt in
            diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten
            werden ausschließlich für die Verarbeitung der Konversation
            verwendet.
          </span>
          <span>
            <b>5.2. Rechtsgrundlage der Datenverarbeitung</b>
            <br />
            Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
            Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit.
            f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages
            ab, so ist die zusätzliche Rechtsgrundlage für die Verarbeitung Art.
            6 Abs. 1 lit. b DSGVO.
          </span>
          <span>
            <b>5.3. Zweck der Datenverarbeitung</b>
            <br />
            Die Verarbeitung der personenbezogenen Daten dient uns allein zur
            Bearbeitung der Kontaktaufnahme. Bei einer Kontaktaufnahme per
            E-Mail liegt hieran auch das erforderliche berechtigte Interesse an
            der Verarbeitung der Daten.
          </span>
          <span>
            <b>5.4. Dauer der Speicherung </b>
            <br />
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Für die
            personenbezogenen Daten, die per E-Mail übersandt wurden, ist dies
            dann der Fall, wenn die jeweilige Konversation mit dem Nutzer
            beendet ist. Beendet ist die Konversation dann, wenn sich aus den
            Umständen entnehmen lässt, dass der betroffene Sachverhalt
            abschließend geklärt ist.
          </span>
          <span>
            <b>5.5. Widerspruchs- und Beseitigungsmöglichkeit </b>
            <br />
            Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der
            Speicherung seiner personenbezogenen Daten jederzeit widersprechen.
            In einem solchen Fall kann die Konversation nicht fortgeführt werden
            und alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
            gespeichert wurden, werden gelöscht.
          </span>
          <span>
            <b>6. Rechte der betroffenen Person </b>
            <br />
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            Betroffener im Sinne der DSGVO und es stehen Ihnen folgende Rechte
            gegenüber dem Verantwortlichen zu. Wenn Sie darüber informiert
            werden möchten, welche personenbezogene Daten wir über Sie haben
            oder wenn Sie möchten, dass diese aus unserem System gelöscht
            werden, dann kontaktieren Sie uns bitte.
          </span>
          <span>
            <b>6.1. Auskunftsrecht </b>
            <br />
            Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das
            Recht Auskunft über Ihre Daten zu erhalten.
          </span>
          <span>
            <b>6.2. Recht auf Berichtigung </b>
            <br />
            Falls wir unrichtige oder unvollständige Information von Ihnen
            haben, steht Ihnen das Recht auf Berichtigung dieser Information zu.
          </span>
          <span>
            <b>
              6.3. Recht auf Einschränkung, Recht auf Löschung und
              Widerspruchsrecht{" "}
            </b>
            <br />
            Liegen die gesetzlichen Voraussetzungen vor, so können Sie die
            Löschung oder Einschränkung der Verarbeitung Ihrer Daten verlangen
            sowie Widerspruch gegen die Verarbeitung einlegen.
          </span>
          <span>
            <b> 6.4. Recht auf Unterrichtung </b>
            <br />
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
            dieser verpflichtet, allen Empfängern, denen Ihre personenbezogenen
            Daten weitergeleitet wurden, diese Berichtigung oder Löschung der
            Daten oder Einschränkung der Verarbeitung mitzuteilen. Es es sei
            denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
            Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
            werden.
          </span>
          <span>
            <b>6.5. Recht auf Datenübertragbarkeit</b>
            <br />
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie dem Verantwortlichen bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
          </span>
          <span>
            <b>
              6.6. Recht auf Widerruf der Datenschutz-Einwilligungserklärung
            </b>
            <br />
            Sie können jederzeit Ihre datenschutzrechtliche
            Einwilligungserklärung widerrufen und somit Ihre davorige
            Einverständnis zurückziehen. Durch den Widerruf der Einwilligung
            wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
            Widerruf erfolgten Verarbeitung nicht berührt. An erster Stelle
            haben Sie auch das Recht, die Datenschutzerklärung abzulehnen.
            Jedoch verhindert dies beispielsweise Ihre erste Kontaktaufnahme mit
            uns.
          </span>
          <span>
            <b>6.7. Beschwerderecht </b>
            <br />
            Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, wenn Sie
            der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die DSGVO verstößt.
          </span>
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Imprint;
