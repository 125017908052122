import React from "react";
import "../Styles/Contact.css";
import Phone from "../images/Phone.svg";
import Mail from "../images/Mail.svg";

function Contact() {
  return (
    <div className="Contact-Container" id="Contact">
      <h1>Kontaktieren Sie uns</h1>
      <div>
        <div className="Icon-Container">
          <img src={Phone} alt="Phone icon"></img>{" "}
          <a href="tel:08281 3411">08281 3411</a>
        </div>
        <div className="Icon-Container">
          <img src={Mail} alt="Mail Icon"></img>{" "}
          <a href="mailto:M.Sahlender@t-online.de">M.Sahlender@t-online.de</a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
