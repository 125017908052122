import React from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import "../Styles/Footer.css";

function Footer() {
  return (
    <footer>
      <Link to="/Impressum#Imprint" className="Policy-Container">
        Impressum & Datenschutz
      </Link>
      <div className="Footer-Text-Container">
        <h2>Bioland Bäckerei M.Sahlender </h2>
        <p>
          Gestaltung und Entwicklung:{" "}
          <a
            href="https://laven-web.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: "0px" }}
          >
            Laven
          </a>
        </p>
        <hr />
      </div>
    </footer>
  );
}

export default Footer;
