import React from "react";
import "../Styles/Ebay.css";
import ebay from "../images/Ebay.svg";

function Ebay() {
  return (
    <div className="Ebay-Container" id="Ebay">
      <img src={ebay} alt="Ebay Logo"></img>
      <h1>Finden Sie uns auch auf Ebay</h1>
      <a
        href="https://www.ebay.de/str/schlender2"
        target="_blank"
        rel="noopener noreferrer"
      >
        Bestellen Sie jetzt
      </a>
    </div>
  );
}

export default Ebay;
