import "./Styles/App.css";
import React from "react";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import Story from "./Components/Story";
import Baking from "./Components/Baking";
import Ebay from "./Components/Ebay";
import Place from "./Components/Place";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <Nav />
      <Home />
      <Story />
      <Baking />
      <Ebay />
      <Place />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
