import React from "react";
import "../Styles/Place.css";
import Map from "../images/Map.png";

function Place() {
  return (
    <div className="Place-Container" id="Place">
      <h1 className="Place-Title-Mobile">Besuchen Sie uns in Person</h1>
      <div className="Place-Image-Container">
        <div
          className="Place-Image"
          style={{ backgroundImage: `url(${Map})` }}
        ></div>

        <p>
          Frühmeßtraße 21
          <br />
          86470 Thannhausen
        </p>
      </div>
      <div className="Place-Text-Container">
        <h1>Besuchen Sie uns in Person</h1>
        <div className="Schedule-Container">
          <div className="Schedule-Days">
            Montag:
            <br />
            Dienstag:
            <br />
            Mittwoch:
            <br />
            Donnerstag:
            <br />
            Freitag:
            <br />
            Samstag:
            <br />
          </div>
          <div className="Schedule-Times">
            Geschlossen
            <br />
            Geschlossen
            <br />
            5:30 - 12:30 Uhr
            <br />
            5:30 - 12:30 Uhr
            <br />
            5:30 - 12:30 Uhr
            <br />
            5:30 - 12:00 Uhr
            <br />
          </div>
          <div className="Schedule-Times">
            <br />
            <br />
            14:00 - 18:00 Uhr
            <br />
            14:00 - 18:00 Uhr
            <br />
            14:00 - 18:00 Uhr
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="Place-Text-Container-Mobile">
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Montag:</b> <br /> Geschlossen
          </p>
          <hr />
        </div>
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Dienstag:</b> <br /> Geschlossen
          </p>
          <hr />
        </div>
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Mittwoch:</b> <br /> 5:30 - 12:30 Uhr <br /> 14:00 - 18:00 Uhr
          </p>
          <hr />
        </div>
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Donnerstag:</b> <br /> 5:30 - 12:30 Uhr <br /> 14:00 - 18:00 Uhr
          </p>
          <hr />
        </div>
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Freitag:</b> <br /> 5:30 - 12:30 Uhr <br /> 14:00 - 18:00 Uhr
          </p>
        </div>
        <div className="Schedule-Container-Mobile">
          <p>
            <b>Samstag:</b> <br /> 5:30 - 12:00 Uhr
          </p>
        </div>
      </div>
    </div>
  );
}

export default Place;
