import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App.js";
import Imprint from "./Components/Imprint.js";

function RouterPaths() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Impressum" element={<Imprint />} />
    </Routes>
  );
}

export default RouterPaths;
